/** 
THEME COLOURS
Please define theme colours of the application here.
*/
@function rgba-to-rgb($rgba, $background: #fff) {
  @return mix(rgb(red($rgba), green($rgba), blue($rgba)), $background, alpha($rgba) * 100%);
}

$hke-purple: rgba-to-rgb(rgba(112, 43, 145, 1));
$hke-purple-90: rgba-to-rgb(rgba(112, 43, 145, 0.9));
$hke-purple-80: rgba-to-rgb(rgba(112, 43, 145, 0.8));
$hke-purple-70: rgba-to-rgb(rgba(112, 43, 145, 0.7));
$hke-purple-60: rgba-to-rgb(rgba(112, 43, 145, 0.6));
$hke-purple-50: rgba-to-rgb(rgba(112, 43, 145, 0.5));
$hke-purple-40: rgba-to-rgb(rgba(112, 43, 145, 0.4));
$hke-purple-30: rgba-to-rgb(rgba(112, 43, 145, 0.3));
$hke-purple-20: rgba-to-rgb(rgba(112, 43, 145, 0.2));
$hke-purple-10: rgba-to-rgb(rgba(112, 43, 145, 0.1));
$hke-blue: rgba-to-rgb(rgba(33, 185, 218, 1));
$hke-blue-90: rgba-to-rgb(rgba(33, 185, 218, 0.9));
$hke-blue-80: rgba-to-rgb(rgba(33, 185, 218, 0.8));
$hke-blue-70: rgba-to-rgb(rgba(33, 185, 218, 0.7));
$hke-blue-60: rgba-to-rgb(rgba(33, 185, 218, 0.6));
$hke-blue-50: rgba-to-rgb(rgba(33, 185, 218, 0.5));
$hke-blue-40: rgba-to-rgb(rgba(33, 185, 218, 0.4));
$hke-blue-30: rgba-to-rgb(rgba(33, 185, 218, 0.3));
$hke-blue-20: rgba-to-rgb(rgba(33, 185, 218, 0.2));
$hke-blue-10: rgba-to-rgb(rgba(33, 185, 218, 0.1));
$hke-white: rgba-to-rgb(rgba(255, 255, 255, 1));
$hke-white-90: rgba-to-rgb(rgba(255, 255, 255, 0.9));
$hke-white-80: rgba-to-rgb(rgba(255, 255, 255, 0.8));
$hke-white-70: rgba-to-rgb(rgba(255, 255, 255, 0.7));
$hke-white-60: rgba-to-rgb(rgba(255, 255, 255, 0.6));
$hke-white-50: rgba-to-rgb(rgba(255, 255, 255, 0.5));
$hke-white-40: rgba-to-rgb(rgba(255, 255, 255, 0.4));
$hke-white-30: rgba-to-rgb(rgba(255, 255, 255, 0.3));
$hke-white-20: rgba-to-rgb(rgba(255, 255, 255, 0.2));
$hke-white-10: rgba-to-rgb(rgba(255, 255, 255, 0.1));
$hke-yellow: rgba-to-rgb(rgba(255, 244, 0, 1));
$hke-yellow-90: rgba-to-rgb(rgba(255, 244, 0, 0.9));
$hke-yellow-80: rgba-to-rgb(rgba(255, 244, 0, 0.8));
$hke-yellow-70: rgba-to-rgb(rgba(255, 244, 0, 0.7));
$hke-yellow-60: rgba-to-rgb(rgba(255, 244, 0, 0.6));
$hke-yellow-50: rgba-to-rgb(rgba(255, 244, 0, 0.5));
$hke-yellow-40: rgba-to-rgb(rgba(255, 244, 0, 0.4));
$hke-yellow-30: rgba-to-rgb(rgba(255, 244, 0, 0.3));
$hke-yellow-20: rgba-to-rgb(rgba(255, 244, 0, 0.2));
$hke-yellow-10: rgba-to-rgb(rgba(255, 244, 0, 0.1));
$hke-dark-green: rgba-to-rgb(rgba(0, 101, 84, 1));
$hke-dark-green-90: rgba-to-rgb(rgba(0, 101, 84, 0.9));
$hke-dark-green-80: rgba-to-rgb(rgba(0, 101, 84, 0.8));
$hke-dark-green-70: rgba-to-rgb(rgba(0, 101, 84, 0.7));
$hke-dark-green-60: rgba-to-rgb(rgba(0, 101, 84, 0.6));
$hke-dark-green-50: rgba-to-rgb(rgba(0, 101, 84, 0.5));
$hke-dark-green-40: rgba-to-rgb(rgba(0, 101, 84, 0.4));
$hke-dark-green-30: rgba-to-rgb(rgba(0, 101, 84, 0.3));
$hke-dark-green-20: rgba-to-rgb(rgba(0, 101, 84, 0.2));
$hke-dark-green-10: rgba-to-rgb(rgba(0, 101, 84, 0.1));
$hke-green: rgba-to-rgb(rgba(191, 215, 48, 1));
$hke-green-90: rgba-to-rgb(rgba(191, 215, 48, 0.9));
$hke-green-80: rgba-to-rgb(rgba(191, 215, 48, 0.8));
$hke-green-70: rgba-to-rgb(rgba(191, 215, 48, 0.7));
$hke-green-60: rgba-to-rgb(rgba(191, 215, 48, 0.6));
$hke-green-50: rgba-to-rgb(rgba(191, 215, 48, 0.5));
$hke-green-40: rgba-to-rgb(rgba(191, 215, 48, 0.4));
$hke-green-30: rgba-to-rgb(rgba(191, 215, 48, 0.3));
$hke-green-20: rgba-to-rgb(rgba(191, 215, 48, 0.2));
$hke-green-10: rgba-to-rgb(rgba(191, 215, 48, 0.1));
$hke-red: rgba-to-rgb(rgba(237, 20, 91, 1));
$hke-red-90: rgba-to-rgb(rgba(237, 20, 91, 0.9));
$hke-red-80: rgba-to-rgb(rgba(237, 20, 91, 0.8));
$hke-red-70: rgba-to-rgb(rgba(237, 20, 91, 0.7));
$hke-red-60: rgba-to-rgb(rgba(237, 20, 91, 0.6));
$hke-red-50: rgba-to-rgb(rgba(237, 20, 91, 0.5));
$hke-red-40: rgba-to-rgb(rgba(237, 20, 91, 0.4));
$hke-red-30: rgba-to-rgb(rgba(237, 20, 91, 0.3));
$hke-red-20: rgba-to-rgb(rgba(237, 20, 91, 0.2));
$hke-red-10: rgba-to-rgb(rgba(237, 20, 91, 0.1));
$hke-orange: rgba-to-rgb(rgba(246, 139, 31, 1));
$hke-orange-90: rgba-to-rgb(rgba(246, 139, 31, 0.9));
$hke-orange-80: rgba-to-rgb(rgba(246, 139, 31, 0.8));
$hke-orange-70: rgba-to-rgb(rgba(246, 139, 31, 0.7));
$hke-orange-60: rgba-to-rgb(rgba(246, 139, 31, 0.6));
$hke-orange-50: rgba-to-rgb(rgba(246, 139, 31, 0.5));
$hke-orange-40: rgba-to-rgb(rgba(246, 139, 31, 0.4));
$hke-orange-30: rgba-to-rgb(rgba(246, 139, 31, 0.3));
$hke-orange-20: rgba-to-rgb(rgba(246, 139, 31, 0.2));
$hke-orange-10: rgba-to-rgb(rgba(246, 139, 31, 0.1));
$hke-beige: rgba-to-rgb(rgba(254, 207, 141));
$hke-beige-90: rgba-to-rgb(rgba(254, 207, 140.9));
$hke-beige-80: rgba-to-rgb(rgba(254, 207, 140.8));
$hke-beige-70: rgba-to-rgb(rgba(254, 207, 140.7));
$hke-beige-60: rgba-to-rgb(rgba(254, 207, 140.6));
$hke-beige-50: rgba-to-rgb(rgba(254, 207, 140.5));
$hke-beige-40: rgba-to-rgb(rgba(254, 207, 140.4));
$hke-beige-30: rgba-to-rgb(rgba(254, 207, 140.3));
$hke-beige-20: rgba-to-rgb(rgba(254, 207, 140.2));
$hke-beige-10: rgba-to-rgb(rgba(254, 207, 140.1));
$hke-brown: rgba-to-rgb(rgba(138, 63, 30, 1));
$hke-brown-90: rgba-to-rgb(rgba(138, 63, 30, 0.9));
$hke-brown-80: rgba-to-rgb(rgba(138, 63, 30, 0.8));
$hke-brown-70: rgba-to-rgb(rgba(138, 63, 30, 0.7));
$hke-brown-60: rgba-to-rgb(rgba(138, 63, 30, 0.6));
$hke-brown-50: rgba-to-rgb(rgba(138, 63, 30, 0.5));
$hke-brown-40: rgba-to-rgb(rgba(138, 63, 30, 0.4));
$hke-brown-30: rgba-to-rgb(rgba(138, 63, 30, 0.3));
$hke-brown-20: rgba-to-rgb(rgba(138, 63, 30, 0.2));
$hke-brown-10: rgba-to-rgb(rgba(138, 63, 30, 0.1));
$hke-navy: rgba-to-rgb(rgba(22, 71, 119, 1));
$hke-navy-90: rgba-to-rgb(rgba(22, 71, 119, 0.9));
$hke-navy-80: rgba-to-rgb(rgba(22, 71, 119, 0.8));
$hke-navy-70: rgba-to-rgb(rgba(22, 71, 119, 0.7));
$hke-navy-60: rgba-to-rgb(rgba(22, 71, 119, 0.6));
$hke-navy-50: rgba-to-rgb(rgba(22, 71, 119, 0.5));
$hke-navy-40: rgba-to-rgb(rgba(22, 71, 119, 0.4));
$hke-navy-30: rgba-to-rgb(rgba(22, 71, 119, 0.3));
$hke-navy-20: rgba-to-rgb(rgba(22, 71, 119, 0.2));
$hke-navy-10: rgba-to-rgb(rgba(22, 71, 119, 0.1));
$hke-black: rgba-to-rgb(rgba(0, 0, 0, 1));
$hke-black-90: rgba-to-rgb(rgba(0, 0, 0, 0.9));
$hke-black-80: rgba-to-rgb(rgba(0, 0, 0, 0.8));
$hke-black-70: rgba-to-rgb(rgba(0, 0, 0, 0.7));
$hke-black-60: rgba-to-rgb(rgba(0, 0, 0, 0.6));
$hke-black-50: rgba-to-rgb(rgba(0, 0, 0, 0.5));
$hke-black-40: rgba-to-rgb(rgba(0, 0, 0, 0.4));
$hke-black-30: rgba-to-rgb(rgba(0, 0, 0, 0.3));
$hke-black-20: rgba-to-rgb(rgba(0, 0, 0, 0.2));
$hke-black-10: rgba-to-rgb(rgba(0, 0, 0, 0.1));

// New Design: https://www.figma.com/file/m0ZGcb6bl29nGbJWJYXvkl/01---HKE---Design-system?type=design&node-id=1-49&mode=design&t=lfh4L66eylpYJBVE-0
// Neutral
$neutral-black: #000000;
$neutral-grey: #4d4d4d;
$neutral-action-disabled-text: #666666;
$neutral-action-disabled-surface: #e5e5e5;
$neutral-border-main: #d6d6d8;
$neutral-border-subdued: #f7f7f7;
$neutral-background-light-grey: #f5f5f5;
$neutral-white: #ffffff;
// Action
$action-purple-focus: #59107d;
$action-purple-default: #702b91;
$action-purple-hover: #8a53a5;
// Secondary
$secondary-purple-decorative: #a980bd;
$secondary-purple-decorative-subdued: #c6aad3;
$secondary-purple-surface: #e2d5e9;
$secondary-purple-surface-subdued: #f8f4f9;
$secondary-blue-default: #21b9da;
$secondary-blue-surface: #d3f1f8;
$secondary-blue-surface-subdued: #e9f8fb;
$secondary-blue-focus: #0da9cb;
$secondary-blue-hover: #4dc7e1;
$secondary-blue-decorative: #7ad5e9;
$secondary-blue-disable: #a6e3f0;
// Accent Color
$accent-promotion-yellow: #feea00;
$accent-promotion-yellow-surface: #fffbcc;
$accent-special-brown-focus: #6a280a;
$accent-special-brown: #8a3f1e;
$accent-special-brown-surface: #f3ece8;
// Additional Color
$additional-success-default: #006554;
$additional-success-surface: #e5f0ee;
$additional-critical-default: #ed145b;
$additional-critical-surface: #fde7ef;
