@import "@hkexpressairwayslimited/ui/build/styles/global";
.bigIcon {
  @include heading-small-size;
  color: $hke-purple;
  height: 32px;
}

.paymentOptionBigIcon {
  @include heading-size;
  color: $hke-purple;
  width: 48px;
  height: 100%;
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 32px;
    height: 24px;
  }
}

.alipayChannelIcon {
  font-size: 56px;
}
