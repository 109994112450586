@import "@hkexpressairwayslimited/ui/build/styles/global";
.paymentionOptionBox {
  border-radius: 8px;
  border: 1px solid;
  border-color: rgba(214, 214, 216, 1);
  outline: none;
  &:focus-visible {
    box-shadow: #59107d 0px 0px 0px 2px;
  }
}

.amex {
  background-color: rgba(31, 114, 205, 1);
  svg {
    color: white;
  }
}

.paymentOptionClickable {
  padding: 8px 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.paymentOptionClickableSelected {
  border-color: $hke-purple-70;
}

.paymentionOptionImg {
  width: 100%;
  height: 100%;
}
