@import "@hkexpressairwayslimited/ui/build/styles/global";
.flightSelectContainer {
  width: 100%;
  max-width: 648px;
  // margin: 20px 0px;
  margin-bottom: 24px;
  flex: 1;
  background-color: $hke-white;
  border-radius: 40px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.flightSelectContainerMobile {
  margin: 20px 0px;
  flex: 1;
  background-color: $hke-white;
  border-radius: 40px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.flightHeader {
  background-color: $hke-white;
  border-radius: 40px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  margin: 0px 5px;
  font-weight: bold;
  cursor: pointer;
}

.selectedFlight {
  background-color: $hke-purple;
  color: $hke-white;
}
