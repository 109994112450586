@import "@hkexpressairwayslimited/ui/build/styles/global";
.inactiveNavButton {
  text-align: center;

  :first-child {
    display: inline-flex;
    border-bottom: 2px solid transparent;
    margin-top: 2px;
  }

  &:hover {
    > :first-child {
      border-bottom: 2px solid $action-purple-focus;
    }
  }
}
