@import "@hkexpressairwayslimited/ui/build/styles/global";
.box:not(:first-child) {
  border-top: 1px dashed #666666;
  padding-top: 16px;
  width: 95%;
}

.box:not(:last-child) {
  padding-bottom: 16px;
  width: 95%;
}

.box {
  width: 95%;
}
