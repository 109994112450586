@import "@hkexpressairwayslimited/ui/build/styles/global";
.generalContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}

.generalContainerMobile {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.personSelectContainer {
  flex: 1;
  margin: 10px 0px 30px;
}

.seatMapContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seatMapContainerDrawer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seatMapContainerMobile {
  width: 100%;
}

.seatMapBox {
  width: 500px;
  // height: 700px;
  // overflow: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-left: 5px solid $hke-purple;
  border-right: 5px solid $hke-purple;
  background-color: $hke-white;
}

.seatMapBoxMobile {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  // border-left: 5px solid $hke-purple;
  // border-right: 5px solid $hke-purple;
  background-color: $hke-white;
}

.container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.colContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
}

.sectionContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.noSeat {
  height: 35px;
  width: 35px;
  margin: 0px 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  border: 2px solid rgba(255, 255, 255, 0);
}

.rowIndex {
  margin: 0px 40px;
  color: $hke-purple;
}

.leftContainer {
  width: 35%;
}

.leftContainerMobile {
  width: 100%;
}

.switchFlightButton {
  border: 1px solid $hke-purple;
  border-radius: 5px;
  display: inline;
  padding: 5px 10px;
  color: $hke-purple;
  cursor: pointer;
}

.switchFlightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.disabledFlightSwitchButton {
  border-color: $hke-black-30;
  color: $hke-black-30;
  pointer-events: none;
}

.flightDetailContainer {
  margin: 0px 0px 40px;
}

.departText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: $hke-purple;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  font-weight: 600;
}

.flightDetailText {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}

.flightDetailTextMobile {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.essentialTag {
  font-size: 0.75em;
  background-color: $hke-purple-10;
  color: $hke-purple;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  padding: 0 0 0 8px;
  font-weight: bold;
  border-radius: 5px;
}

.notesBox {
  margin-top: 24px;
  width: 90%;
}

.notesText {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 8px;
}

.linkText {
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  display: inline;
  color: $hke-purple;
  cursor: pointer;
}

// Seat classes

.standardSeat {
  height: 35px;
  width: 35px;
  margin: 0px 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  border: 2px solid $hke-blue;
  cursor: pointer;
  min-width: 35px;
}

.seatStyleBox {
  height: 7px;
  width: 80%;
  background-color: $hke-blue;
  border-radius: 20px;
  margin-top: 15px;
}

.upfrontSeat {
  border-color: $hke-purple;
  div {
    background-color: $hke-purple;
  }
}

.extraLegSeat {
  height: 45px;
  border-color: $hke-brown;
  div {
    background-color: $hke-brown;
    margin-top: 25px;
  }
}

.unavailableExtraLegSeatSeat {
  height: 45px;
  border-color: $hke-black-40;
  div {
    background-color: #d6d6d8;
    margin-top: 25px;
  }
}

.standardSeatSelected {
  background-color: $hke-blue;
  div {
    background-color: $hke-white;
  }
}

.upfrontSeatSelected {
  background-color: $hke-purple;
  div {
    background-color: $hke-white;
  }
}

.extraLegSeatSelected {
  background-color: $hke-brown;
  div {
    background-color: $hke-white;
  }
}

.unavailableSeat {
  border-color: $hke-black-40;
  div {
    background-color: #d6d6d8;
  }
}

.expandIcon {
  color: $hke-brown;
  height: 17px;
  width: 17px;
  position: absolute;
  top: 7px;
}

// Pax Select Classes

.personSelectBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid $hke-black-40;
  border-radius: 10px;
  margin: 10px 0px;
  cursor: pointer;
}

.selectedPersonBox {
  border-color: $hke-purple;
  background-color: $hke-purple-10;
}

.paxIconBox {
  min-width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding: 5px;
  border-radius: 5px;
}

.paxIconText {
  height: 35px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
}

.paxIcon {
  width: 32px;
  height: 32px;
}

.closeIcon {
  width: 24;
  height: 24;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(203, 185, 185, 0.5);
  }
}

.discountText {
  font-size: 0.7em;
  color: $hke-brown;
  font-weight: bold;
}

.seatBox {
  padding: 5px;
  background-color: $hke-blue;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 0.8em;
  font-weight: bold;
  min-width: 35px;
  display: flex;
  justify-content: center;
  color: $hke-white;
  margin-bottom: 12px;
  &_withChild {
    margin-bottom: 32px;
  }
}

.upfrontBox {
  background-color: $hke-purple;
}

.extraLegBox {
  background-color: $hke-brown;
}

.purchasedSeat {
  color: $hke-black;
  font-weight: 600;
}
// Seat Type Bar Classes

.seatTypeBarContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  align-self: stretch;
  margin: 15px 0px 30px;
}

.seatTypeBarContainerDrawer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  margin: 15px 0px 30px;
}

.seatTypeBarContainerMobile {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  align-self: stretch;
  margin: 15px 0px 30px;
  flex-wrap: wrap;
  row-gap: 12px;
}

.seatType {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.seatTypeMobile {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.seatTypePrice {
  color: $hke-purple;
  font-weight: bold;
  display: flex;
}

.seatTypeDescription {
  font-size: 10px;
  line-height: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

.seatTypeBarToolTip {
  margin-top: 25px;
}

.seatTypeBarRemark {
  border-bottom: 1px solid #d6d6d8;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.seatTypeBarPopUpContainer {
  display: flex;
  flex-direction: column;
}

.seatTypeBarPopUpSubContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// Flight Select Classes

.flightSelectContainer {
  margin: 20px 0px;
  flex: 1;
  background-color: $hke-white;
  border-radius: 40px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.flightSelectContainerMobile {
  margin: 20px 0px;
  flex: 1;
  background-color: $hke-white;
  border-radius: 40px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.flightHeader {
  background-color: $hke-white;
  border-radius: 40px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  margin: 0px 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 22px;
}

.flightHeaderMobile {
  background-color: $hke-white;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  &_OW {
    width: 100% !important;
  }
  &_RT {
    width: 50% !important;
  }
  &_MT {
    width: 33.3% !important;
  }
}

.selectedFlight {
  background-color: $hke-purple;
  color: $hke-white;
  width: 50%;
}

// Seat Tooltip Classes
.tooltipTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tooltipHeading {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: -5px;
}

// Fare Type Banner

.bannerContainer {
  margin: 32px 0px;
  display: flex;
  flex-direction: row;
  background-color: $hke-white;
  border-radius: 16px;
  align-items: center;
}

.bannerTextContainer {
  padding: 30px;
}

.bannerTitle {
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;
}

.bannerTitleHighlight {
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;
  color: $hke-purple;
  display: inline;
}

.bannerSubText {
  margin-top: 8px;
}

//Emergency Exit Popup

.emergencyExitPopupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emergencyExitHeading {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}

.emergencyExitText {
  text-align: center;
  margin-bottom: 8px;
}

.emergencyExitButton {
  width: 100%;
  margin-bottom: 8px;
  border-radius: 8px;
}

.emergencyExitTermsText {
  display: inline;
  text-align: center;
  margin-bottom: 8px;
  color: $hke-purple;
  cursor: pointer;
}

// Emergency Exit Map Render

.emergencyExit {
  z-index: 1;
  position: absolute;
  display: flex;
  width: 30px;
  height: 18px;
  background-color: $hke-white;
  padding: 1px;
  border-radius: 2px;
  border: 1px solid $hke-purple;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
}

.mmbEmergencyExit {
  z-index: 0;
  position: absolute;
  display: flex;
  width: 30px;
  height: 18px;
  background-color: $hke-white;
  padding: 1px;
  border-radius: 2px;
  border: 1px solid $hke-purple;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
}

.emergencyExitDisplayText {
  color: $hke-purple;
}

.leftEmergencyExit {
  left: -32px;
}

.mmbLeftEmergencyExit {
  left: -32px;
}

.rightEmergencyExit {
  right: -32px;
}

.mmbRightEmergencyExit {
  right: -32px;
}

//Bathroom
.bathroom {
  color: $hke-purple;
  font-size: 35px;
  margin: 0px 7px;
}

.toiletRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.invisibleBathroom {
  opacity: 0;
}

//Crew Seat & Exit Door

.disabledSeatWithText {
  border-color: $hke-black-30;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: $hke-black-30;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
}

//Wing

.wing {
  position: sticky;
  background-color: transparent;
  height: 100%;
  width: 100px;
  // overflow: scroll;
  justify-content: center;
  align-items: center;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

.wingBox {
  position: relative;
  background-color: $hke-purple;
  height: 495px;
  width: 100px;
  // top: 500px;
  overflow: hidden;
  // top: 100px;
}

.flightBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  height: 700px;
}

.flightBoxMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 105%;
}

// responsive

@media only screen and (max-width: 515px) {
  .seatMapBoxMobile {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    background-color: $hke-white;
  }

  .leftEmergencyExit {
    left: 6px;
  }
  .rightEmergencyExit {
    right: 6px;
  }
  .standardSeat {
    height: 32px;
    width: 32px;
    margin: 0px 3px;
    min-width: 32px;
  }
  .unavailableExtraLegSeatSeat {
    height: 45px;
  }

  .noSeat {
    height: 32px;
    width: 32px;
    margin: 0px 3px;
    min-width: 32px;
  }
  @media screen and (max-width: 424px) {
    .rowIndex {
      margin: 0px -2px;
      color: $hke-purple;
    }
  }
  @media screen and (min-width: 425px) {
    .rowIndex {
      margin: 0px 16px;
      color: $hke-purple;
    }
  }
  .extraLegSeat {
    height: 45px;
  }
  .disabledSeatWithText {
    font-size: 6px;
  }
}
